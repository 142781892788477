import React, { Component } from 'react';
import { Loader } from '@/shared/components/loader/Loader';

class Loading extends Component {
  _renderLoading() {
    const { isLoading } = this.props;

    if (isLoading) {
      return (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-[rgba(0,0,0,.7)]">
          <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
        </div>
      );
    }

    return null;
  }

  render() {
    return <React.Fragment>{this._renderLoading()}</React.Fragment>;
  }
}

export default Loading;
