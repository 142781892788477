import React from 'react';
import MenuListOdv from './odv';
import MenuListW from './project-w';
import { tenantEnvConfig } from '@/constants/tenantConfig/tenantEnvConfig';

export default class MenuList extends React.Component {
  render() {
    let Component;
    switch (tenantEnvConfig.ui_theme) {
      case 'projectw':
        Component = MenuListW;
        break;
      default:
        Component = MenuListOdv;
    }
    return <Component {...this.props} />;
  }
}
