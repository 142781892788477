import React from 'react';
import PropTypes from 'prop-types';
import NavigationTop from './components/navigation-top';
import Submenu from './components/sub-menu';
import { styled } from 'twin.macro';
import { connect } from 'react-redux';
import './header.scss';
import Logo from './components/logo';

class HeaderW extends React.Component {
  render() {
    const { isFollowSubscription, showLoginAction, isHiddenMenu } = this.props;
    if (isHiddenMenu) {
      return null;
    }
    return (
      <RowStyled className="header" props={{ isFollowSubscription }}>
        <div className="headerContainer">
          <Logo />
          <div className="v-header--right-corner">
            <Submenu />
            <NavigationTop
              showLoginAction={() => showLoginAction()}
              style={{ backgroundColor: '#00000040' }}
            />
            <div id="bg-dark" />
          </div>
        </div>
      </RowStyled>
    );
  }
}
HeaderW.propTypes = {
  showLoginAction: PropTypes.func,
};

const RowStyled = styled.div`
  & .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    /* width: 100%; */
    height: 70px;
    top: 0;
    background: ${({ theme }) => theme.header.background};
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    z-index: 999;
    & .v-logo {
      padding-left: 15px;
    }
  }
  & .v-header--right-corner {
    position: relative;
    margin-left: 15px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    & .navigationTop {
      padding-right: 15px;
      z-index: 1;
    }
    & #bg-dark {
      position: absolute;
      top: 0;
      right: -64px;
      width: 100%;
      height: 100%;
      transform: skew(-20deg);
      background-color: #00000040;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    .headerContainer .v-logo {
      padding-left: 45px;
    }
    & .v-header--right-corner {
      & .navigationTop {
        padding-right: 45px;
      }
    }
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (max-width: 1024px) and (orientation: landscape) {
  }
`;

const mapStateToProps = state => ({
  isFollowSubscription: state.root.isFollowSubscription,
});

export default connect(mapStateToProps, null)(HeaderW);
