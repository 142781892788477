import React from 'react';
import { getAccountInfo, getProfile } from '../../features/profile/services';
import { connect } from 'react-redux';
import HeaderOdv from './headerOdv';
import HeaderW from './headerW';
import { tenantEnvConfig } from '../../constants/tenantConfig/tenantEnvConfig';
import { withKeyByAccountId } from '@/core/hoc/withKeyByAccountId';

class Header extends React.Component {
  componentWillMount() {
    const { dpGetAccountInfo, account, dpGetProfile } = this.props;
    if (!account || !account.profile) {
      return null;
    }
    if (account.profile.subscription_plan_info && !account.profile.subscription_plan_info.slug) {
      return dpGetProfile().catch(error => console.log('error', error.message));
    }
    // dpGetAccountInfo().catch(error => console.log('error', error.message));
  }

  render() {
    const { isHiddenMenu } = this.props;

    if (isHiddenMenu) {
      return null;
    }
    let Component;
    switch (tenantEnvConfig.ui_theme) {
      case 'projectw':
        Component = HeaderW;
        break;
      default:
        Component = HeaderOdv;
    }
    return <Component {...this.props} />;
  }
}

const mapDispatchToProps = {
  dpGetAccountInfo: () => getAccountInfo(),
  dpGetProfile: () => getProfile(),
};

const mapStateToProps = state => ({
  account: state.auth.account,
  isHiddenMenu: state.global.menu.isHidden,
});

export default connect(mapStateToProps, mapDispatchToProps)(withKeyByAccountId(Header));
