import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { styled } from 'twin.macro';
import { Nav, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class MenuListW extends React.Component {
  render() {
    let ls = { profile: 'profile', help: 'help' };
    const { menu, location, t } = this.props;
    let params = location.pathname ? location.pathname.split('/')[2] : '';
    params = { slug: params || null };
    let slug = '';
    if ('slug' in params) slug = params.slug;
    return (
      <StyledComp className="v-subMenu--menu js-v-subMenu-scroll">
        {menu &&
          menu
            .filter((_, i) => i < 5)
            .map((item, index) => {
              const isActive =
                item.slug === slug || (!slug && index === 0) ? 'v-subMenu--active' : '';
              let hrefUrl =
                item.display_style === 'YOUTUBE' ? `/youtube/${item.slug}` : `/page/${item.slug}`;
              if (index === 0) hrefUrl = '/';
              return (
                <li className={isActive + ' v-subMenu--menu--item'}>
                  <Link className={isActive} to={`${hrefUrl}`}>
                    <h4 className="v-subMenu--name">{item.name} </h4>
                  </Link>
                </li>
              );
            })}
        {location.pathname.split('/')[1] in ls ? (
          <NavItem href={`/profile/overview`} style={{ padding: 0 }}>
            <h4 style={{ color: '#2574d4' }}>{t('navigation.account')}</h4>
          </NavItem>
        ) : null}
      </StyledComp>
    );
  }
}

const StyledComp = styled(Nav)`
  display: flex;
  align-items: center;
  padding-left: 15px;
  & span {
    display: inline-block;
    align-items: flex-start;
    color: #666666;
    width: 1px;
    min-width: 1px;
    height: 80%;
    background-color: #666666;
    align-self: center;
  }
  & span:last-child {
    display: none;
  }
  li {
    white-space: nowrap;
    padding: 0;
    a {
      padding: 0 1rem;
      h4 {
        color: #fff;
        font-weight: 600;
        margin: 0;
        &:hover {
          color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
  }
  &:first-child {
    display: flex;
    li:first-child {
      a {
        padding: 0;
      }
    }
  }
  &.v-subMenu {
    &--menu {
      &:before,
      &:after {
        display: none;
      }
      display: flex;
      flex-grow: 1;
      width: 0;
      overflow: auto;
      overflow-y: hidden;
      transition: all 0.25s;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  & .v-subMenu {
    &--active {
      a {
        h4 {
          color: white !important;
          font-weight: 800;
        }
      }
    }
    &--active a {
      padding: 15px 10px;
      background-color: #0b28ba40 !important;
      transform: skew(-20deg);
    }
    &--menu--item {
      padding: 0;
      display: flex;
    }
  }
`;

const mapStateToProps = state => ({
  menu: state?.tenantConfig?.platformConfig?.tenant_pages ?? [],
});

export default withRouter(connect(mapStateToProps)(withTranslation()(MenuListW)));
