import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

const overrideMapBySlug = new Map([[]]);

/**
 *
 * @param {String} slug
 * @returns String
 */
export const overrideMenuLink = (slug = '') => {
  return overrideMapBySlug.get(slug);
};

class MenuItem extends React.PureComponent {
  render() {
    const { isActive, hrefUrl, value, menu } = this.props;
    const overrideLink = overrideMenuLink(menu?.slug);
    const isExternalLink = menu?.display_style === 'EXTERNAL_LINK';
    const href = isExternalLink ? menu?.external_link : (overrideLink ?? hrefUrl);
    const target = !overrideLink && !isExternalLink ? '_self' : '_blank';
    return (
      <React.Fragment>
        <li className={`menu-item ${isActive}`} style={{ padding: 0, display: 'flex' }}>
          <Link className={isActive} to={{ pathname: href }} target={target}>
            <h4 className="v-subMenu--name">{value.name} </h4>
          </Link>
          <span />
        </li>
      </React.Fragment>
    );
  }
}
MenuItem.propTypes = {
  value: PropTypes.any,
  isActive: PropTypes.string,
  hrefUrl: PropTypes.string,
};

export default withRouter(MenuItem);
