import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ButtonToolbar, Dropdown, MenuItem } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { changeLanguage } from '@/app/services';
import { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { tenantEnvConfig } from '@/constants/tenantConfig/tenantEnvConfig';
import { getLocales } from '@/shared/utils/locales';
import { AVAILABEL_LOCALES, DEFAULT_LOCALE } from '@/i18n';
import pushAnalytics from '@/services/analytics/Analytics';
import _ from 'lodash';

const locales = getLocales();
class SwitchLanguage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line no-undef
      language: DEFAULT_LOCALE,
    };
  }

  componentDidMount() {
    const { language } = this.props;
    if (!AVAILABEL_LOCALES.includes(language)) {
      this._actionChange(DEFAULT_LOCALE);
    }
  }

  _actionChange(value) {
    const { i18n, dispatchChangelanguage, history, planSelected } = this.props;
    let itemName = 'selected language in vietnamese';
    if (value === 'en') {
      itemName = 'selected language in english';
    }
    i18n.changeLanguage(value);
    dispatchChangelanguage(value).then(() => {
      pushAnalytics('click', { content_type: 'button', item_name: itemName });
      let url = window.location.pathname;
      let search = window.location.search;
      const ls = { en: 'en', vi: 'vi' };
      const pathSplit = url.split('/');
      if (pathSplit.length > 1 && pathSplit[1] in ls) {
        pathSplit[1] = value;
        url = pathSplit.join('/');
        // window.location.replace(`${url}${search}`)
        history.replace(`${url}${search}`, {
          upgrade: planSelected && planSelected.upgrade ? planSelected.upgrade : null,
        });
        setTimeout(function () {
          window.location.reload();
        }, 100);
      } else {
        setTimeout(function () {
          window.location.reload();
        }, 500);
      }
    });
  }

  render() {
    const { language, t } = this.props;
    const disableSelect = _.isEmpty(locales) || locales.length <= 1;
    return (
      <StyledButton disableSelect={disableSelect}>
        <Dropdown id="language">
          <Dropdown.Toggle
            bsRole="toggle"
            className={tenantEnvConfig.ui_theme === 'projectw' && 'rounded'}
          >
            <span className="slash"></span>
            <span className="language--name">{language || 'vi'}</span>
          </Dropdown.Toggle>
          {!disableSelect && (
            <Dropdown.Menu style={styles.submenu}>
              {locales.map(locale => {
                if (locale === language) {
                  return null;
                }
                return (
                  <MenuItem
                    onClick={() => this._actionChange(locale)}
                    style={{
                      margin: '0',
                      float: 'left',
                      color: '#002d7a',
                      cursor: 'pointer',
                    }}
                  >
                    {t(`locale.${locale}`)}
                  </MenuItem>
                );
              })}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </StyledButton>
    );
  }
}
SwitchLanguage.propTypes = {
  onChange: PropTypes.func,
};

const styles = {
  submenu: {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    top: 35,
    right: 0,
    left: 'auto',
    padding: 10,
    borderRadius: 4,
    boxShadow: '0px 15px 36px 5px rgba(0,0,0,0.45)',
  },
};

const StyledButton = styled(ButtonToolbar)`
  & .dropdown-toggle,
  & .btn-default.active.focus,
  & .btn-default.active:focus,
  & .btn-default.active:hover,
  & .btn-default:active.focus,
  & .btn-default:active:focus,
  & .btn-default:active:hover,
  & .open > .dropdown-toggle.btn-default.focus,
  & .open > .dropdown-toggle.btn-default:focus,
  & .open > .dropdown-toggle.btn-default:hover {
    border: none !important;
    background: none;
    display: flex;
    justify-content: center;
    cursor: pointer;
    box-shadow: none;
  }
  & {
    pointer-events: ${props => (props.disableSelect ? 'none' : '')};
    .dropdown {
      #language {
        .caret {
          display: ${props => (props.disableSelect ? 'none' : '')};
        }
      }
    }
  }
  & .rounded {
    span:first-child {
      font-size: 18px;
    }
    .language--name {
      background-color: #428ffc;
      color: white;
      border-radius: 50%;
      padding: 3px;
      width: 25px;
      font-size: 0.9rem;
    }
    .caret {
      display: none;
    }
    & + ul {
      margin-top: 10px;
    }
  }
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1280px) {
  }

  @media (min-width: 1440px) {
  }

  @media (min-width: 1680px) {
  }
`;

const mapDispatchToProps = {
  dispatchChangelanguage: value => changeLanguage(value),
};

const mapStateToProps = state => ({
  language: state.root.language,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SwitchLanguage)),
);
