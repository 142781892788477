import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { styled } from 'twin.macro';
import { Nav, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import MenuItem from '../menu-item';
import queryString from 'query-string';

const StyledComp = styled(Nav)`
  display: flex;
  & li span {
    display: inline-block;
    align-items: flex-start;
    color: #666666;
    width: 1px;
    min-width: 1px;
    height: 80%;
    background-color: #666666;
    align-self: center;
  }
  & li:last-child span {
    display: none;
  }
  li {
    white-space: nowrap;
    padding: 0;
    a {
      padding: 0px 1rem;
      h4 {
        color: #fff;
        font-weight: 600;
        margin: 0;
        &:hover {
          color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
  }
  &:first-child {
    display: flex;
    li:first-child {
      a {
        padding: 0;
      }
    }
  }
  &.v-subMenu {
    &--menu {
      scrollbar-width: none;
      &:before,
      &:after {
        display: none;
      }
      display: flex;
      flex-grow: 1;
      width: 0;
      overflow-x: auto;
      overflow-y: hidden;
      transition: all 0.25s;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
      li {
        scroll-snap-align: start;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  & .v-subMenu {
    &--active {
      h4 {
        color: ${({ theme }) => theme.primaryColor}!important;
        font-weight: 800;
      }
    }
  }
`;

function MenuListOdv(props) {
  const ls = { profile: 'profile', help: 'help' };
  const { menu, location, t } = props;

  let params = location.pathname ? location.pathname.split('/')[2] : '';
  params = { slug: params || null };
  let slug = '';
  const queryParams = queryString.parse(location.search, {
    decode: false,
  });
  if ('slug' in params) slug = params.slug;

  const menuMemo = useMemo(() => {
    if (menu) {
      return menu.map((item, index) => {
        const active =
          item.slug === slug ||
          (location.pathname === '/' && index === 0) ||
          queryParams.tenants === item.slug
            ? 'v-subMenu--active'
            : '';
        let hrefUrl =
          item.display_style === 'YOUTUBE' ? `/youtube/${item.slug}` : `/page/${item.slug}`;
        if (index === 0) hrefUrl = '/';

        return {
          ...item,
          hrefUrl,
          active,
        };
      });
    }
    return [];
  }, [menu, queryParams]);

  return (
    <StyledComp className="v-subMenu--menu js-v-subMenu-scroll">
      {menuMemo.map((item, index) => {
        return (
          <MenuItem
            key={index}
            value={item}
            isActive={item.active}
            hrefUrl={item.hrefUrl}
            menu={item}
          />
        );
      })}
      {location.pathname.split('/')[1] in ls ? (
        <NavItem href={`/profile/overview`} style={{ padding: 0 }}>
          <h4 style={{ color: '#2574d4' }}>{t('navigation.account')}</h4>
        </NavItem>
      ) : null}
    </StyledComp>
  );
}

const mapStateToProps = state => ({
  menu: state?.tenantConfig?.platformConfig?.tenant_pages ?? [],
});

export default withRouter(connect(mapStateToProps)(withTranslation()(MenuListOdv)));
