import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import navBarId from '@/constants/ids/navBarId';
import pushAnalytics from '@/services/analytics/Analytics';
import classnames from 'classnames';

class SearchBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isActiveTextBoxSearch: false,
      stext: '',
      search: 0,
    };
    this.updateSearchValue = e => {
      this.setState({
        stext: e.target.value,
      });
    };
  }

  componentDidMount() {
    document.addEventListener('click', this._handleClickOutSite.bind(this));
  }

  componentWillReceiveProps() {
    if (window.location.href.indexOf('search') === -1) {
      this.setState({
        search: 0,
        stext: '',
        isActiveTextBoxSearch: false,
      });
    }
  }

  openSearch() {
    this.setState({
      isActiveTextBoxSearch: true,
    });
  }

  searchClick(e) {
    const { stext } = this.state;
    if (stext !== '') {
      if (e.type === 'keyup' && e.keyCode !== 13) {
        return false;
      }
      if (stext.trim() === '') {
        return;
      }
      let search = encodeURIComponent(stext);
      this.props.history.push(`/search/${search}`);
      return false;
    }
  }

  _handleClickOutSite(event) {
    const { target } = event;
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(target)) {
      this.setState({
        isActiveTextBoxSearch: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { isActiveTextBoxSearch, stext } = this.state;
    return isActiveTextBoxSearch ? (
      <li
        className="is_open v-search"
        style={{ background: '#fff', borderRadius: '25px' }}
        ref={this.wrapperRef}
      >
        <i className="fa fa-search" style={{ paddingLeft: '1rem' }} aria-hidden="true" />
        <input
          data-testid={navBarId.searchTxt}
          style={{ border: 'none', fontSize: 16, color: '#000000' }}
          className="txt-search"
          type="text"
          placeholder={t('navigation.search')}
          id="search_txt"
          value={stext}
          onChange={this.updateSearchValue}
          onKeyUp={e => this.searchClick(e)}
        />
      </li>
    ) : (
      // todo check tenant is ProjectW and set className for Dropdown.Toggle is wrapper
      <li
        className={classnames('v-search v-nav--search', 'wrapper')}
        data-testid={navBarId.searchBtn}
        ref={this.wrapperRef}
      >
        <i
          className="fa fa-search icon"
          onClick={() => {
            this.openSearch();
            pushAnalytics('click', { content_type: 'button', item_name: 'search' });
          }}
        />
      </li>
    );
  }
}
SearchBox.propTypes = {
  dispatchLogout: PropTypes.func,
  showLoginAction: PropTypes.func,
};
export default withRouter(withTranslation()(SearchBox));
