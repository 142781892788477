import React from 'react';
import Header from '@/shared/components/header/header';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/features/loadingPage/view/loadingPage';

import routes from '@/shared/routes';
import { routePageNames as routeName } from '@/core/routes';
import { useDispatch, useSelector } from 'react-redux';
import { showForgotPassModal, showLoginModal } from '@/shared/components/global/globalServices';
import loadable from '@loadable/component';

const Footer = loadable(() => import('@/shared/features/footer/footer'));

const DefaultRouter = props => {
  const dispatch = useDispatch();

  const _showLoginModal = param => {
    dispatch(showLoginModal(param));
  };
  const { component } = props;
  const isLoading = component.props.isLoading;

  return (
    <>
      <Header showLoginAction={() => _showLoginModal()} />
      <RouterSwitch component={component} />
      <Footer block={['box', 'detail', 'block']} />
      <Loading isLoading={isLoading} />
    </>
  );
};

const RouterSwitch = ({ component }) => {
  const dispatch = useDispatch();
  const blockCountry = useSelector(state => state?.blockCountry);

  const _showLoginModal = param => {
    dispatch(showLoginModal(param));
  };
  const _showForgotPassModal = () => {
    dispatch(showForgotPassModal());
  };

  return (
    <Switch>
      {routes
        .filter(route => {
          return blockCountry?.isBlock
            ? route.path === routeName.blockCountry
            : route.path !== routeName.blockCountry;
        })
        .map((route, index) => {
          const Component = route.main();

          if (Array.isArray(route?.router)) {
            return route.router.map((item, indexs) => {
              let SwitchComponent = item.flag ? item.main() : Component;
              return (
                <Route key={indexs} path={item.path} exact={route.exact}>
                  <SwitchComponent
                    showLoginAction={param => _showLoginModal(param)}
                    showForgotPassAction={() => _showForgotPassModal()}
                    {...(item.data || {})}
                  />
                </Route>
              );
            });
          }
          return (
            <Route key={index} path={route.path} exact={route.exact}>
              <Component
                showLoginAction={param => _showLoginModal(param)}
                showForgotPassAction={() => _showForgotPassModal()}
              />
            </Route>
          );
        })}
    </Switch>
  );
};

export default DefaultRouter;
