import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';
import './header.scss';
import NavigationTop from './components/navigation-top';
import Submenu from './components/sub-menu';
import { connect } from 'react-redux';
import Logo from './components/logo';

const RowStyled = styled.div`
  & + div {
    position: relative;
    flex-grow: 1;
  }
  & .headerContainer {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* position: fixed; */
    /* width: 100%; */
    height: 70px;

    background: rgba(0, 0, 0, 0.88);
  }
  &.v-header {
  }
  .v-header {
    &--menu {
      width: auto;
    }
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .headerContainer {
      /* padding: 0 45px; */
    }
    .btn-toolbar > .btn-group {
      margin-left: 0;
    }
    .v-nav--desktop {
      .dropdown-toggle {
        display: flex;
        align-items: center;
      }
      .slash {
        border-color: #c2c2c2;
      }
    }
  }

  @media (min-width: 992px) {
    & .headerContainer {
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* position: fixed; */
      /* width: 100%; */
      height: 70px;
      /* top: 0; */

      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 100%);
      z-index: 999;
    }
    .v-header {
      &--menu {
        /* width: 50%;
        .js-v-subMenu__menu {
          width: 60%;
        }
        .v-subMenu {
          width: 100%;
        } */
      }
    }
  }
  @media (min-width: 1280px) {
  }

  @media (min-width: 1440px) {
    .v-header {
      &--menu > .js-v-subMenu__menu,
      .v-subMenu {
        width: auto;
      }
    }
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    .headerContainer {
      .v-subMenu {
        display: none !important;
      }
      .navigationTop {
        .v-nav--desktop {
          display: none;
        }
        .v-nav--mobile {
          display: block;
        }
        .isIpad {
          display: block !important;
        }
      }
    }
  }
`;

function HeaderOdv(props) {
  const { isFollowSubscription, showLoginAction } = props;
  return (
    <RowStyled className="header" props={{ isFollowSubscription }}>
      <div className="headerContainer ">
        <div style={{ display: 'flex', flexGrow: 1 }} className="v-header--menu">
          <Logo className="hidden-md hidden-lg" small />
          <Submenu />
        </div>
        <NavigationTop showLoginAction={() => showLoginAction()} />
      </div>
    </RowStyled>
  );
}

HeaderOdv.propTypes = {
  showLoginAction: PropTypes.func,
};

const mapStateToProps = state => ({
  isFollowSubscription: state.root.isFollowSubscription,
});

export default connect(mapStateToProps, null)(HeaderOdv);
