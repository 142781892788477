import jquery from 'jquery';
import { throttle } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { getConfigByKey } from '@/shared/features/tenantConfig/services';

// import SubMenuEscondido from './escondido';
import SubMenuOdv from './odv';

// import SubMenuW from './w';

class ODV extends React.Component {
  componentDidMount() {
    this.enableScroll();

    window.addEventListener('resize', this.autoScrollFirst, false);
    jquery('body').on('click', '.menu-item', this.handleMenuItemClick);
    const $wrap = jquery('body .js-v-subMenu__menu');
    const $scroll = $wrap.find('.js-v-subMenu-scroll');

    $scroll.on('scroll', this.initHandleScrollEvent);
    $scroll.trigger('scroll');
  }

  componentWillUnmount() {
    const $wrap = jquery('body .js-v-subMenu__menu');
    const $scroll = $wrap.find('.js-v-subMenu-scroll');
    window.removeEventListener('resize', this.autoScrollFirst, false);
    jquery('body').off('click', '.menu-item', this.handleMenuItemClick);

    $scroll.off('scroll');
  }

  enableScroll() {
    this.autoScrollFirst();
  }

  initHandleScrollEvent = throttle(event => {
    const $scroll = jquery(event.target);
    const $wrap = $scroll.parents('.js-v-subMenu__menu');
    if ($scroll.get(0).scrollLeft === 0) {
      $wrap.addClass('start-scroll');
    } else {
      $wrap.removeClass('start-scroll');
    }
    if ($scroll.get(0).scrollWidth <= $scroll.get(0).scrollLeft + Math.ceil($scroll.outerWidth())) {
      $wrap.addClass('end-scroll');
    } else {
      $wrap.removeClass('end-scroll');
    }
    this.setCurrentScroll($scroll);
  }, 500);

  autoScrollFirst = throttle(() => {
    const $wrap = jquery('.js-v-subMenu__menu');
    const $scroll = $wrap.find('.js-v-subMenu-scroll');
    const firstLi = $scroll.find('li').first();
    if (!$scroll.length || $wrap.hasClass('end-scroll')) {
      return;
    }

    if ($scroll.get(0).scrollWidth - $scroll.outerWidth() > 0) {
      $wrap.addClass('has-scroll');
    } else {
      $wrap.removeClass('has-scroll');
    }
    let $active = $scroll.find('li.v-subMenu--active');
    if (!$active.length && !firstLi.length) {
      return;
    }
    if (!$active.length && firstLi.length) {
      $active = firstLi;
    }
    this.scrollElementToView($active);
  }, 1000);

  setCurrentScroll = $container => {
    const $scroll = $container?.length ? $container : jquery('.js-v-subMenu-scroll');
    const container = $scroll.get(0);
    [].slice.call(container.children).forEach(function (ele, index) {
      if (
        Math.abs(ele.getBoundingClientRect().left - container.getBoundingClientRect().left) < 20
      ) {
        jquery(ele).addClass('current-scroll');
      } else {
        jquery(ele).removeClass('current-scroll');
      }
    });
  };

  handleMenuItemClick = event => {
    const $target = jquery(event.currentTarget);
    this.scrollElementToView($target);
  };

  scrollElementToView = $element => {
    // jquery('.current-scroll').removeClass('current-scroll');
    $element[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };

  handleClickScroll = event => {
    const $el = jquery(event.target);
    const $wrap = $el.parents('.js-v-subMenu__menu');
    const $scroll = $wrap.find('.js-v-subMenu-scroll');
    let $current = $scroll.find('li.current-scroll');

    if ($current.length === 0) {
      $current = $scroll.find('li.v-subMenu--active');
    }

    let itemView = null;

    if (!$scroll.length || !$current.length) {
      return;
    }
    if ($el.hasClass('v-subMenu--prev')) {
      itemView = $current.prevAll('li').first();
    } else {
      itemView = $current.nextAll('li').first();
    }
    window.itemView = itemView;
    if (!itemView.length) {
      return;
    }

    // $current.removeClass('current-scroll');
    this.scrollElementToView(itemView.addClass('current-scroll'));
  };

  render() {
    let Component;
    switch (getConfigByKey('ui_theme')) {
      // case 'projectw':
      //   Component = SubMenuW;
      //   break;
      // case 'escondido':
      //   Component = SubMenuEscondido;
      //   break;
      default:
        Component = SubMenuOdv;
    }
    return <Component {...this.props} handleClickScroll={this.handleClickScroll} />;
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  tenantConfigs: state.tenantConfigs,
});

export default connect(mapStateToProps, mapDispatchToProps)(ODV);
