import React from 'react';
import { Navbar } from 'react-bootstrap';
import { styled } from 'twin.macro';
import Logo from '../logo';
import MenuList from '../menu-list';

const NavbarStyled = styled(Navbar)`
  .container {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    /* padding-top: 1.2rem; */
    margin-left: 0;
  }
  .v-subMenu {
    &__menu {
      position: relative;
      display: flex;
      flex-grow: 1;
      padding: 0 1rem;
      margin: 0 2rem;
      .v-subMenu--name {
        font-size: 18px;
        line-height: 1.4;
      }
    }
    &--btn {
      position: absolute;
      display: none;
      background-color: transparent;
      color: white;
      top: calc(50%);
      transform: translateY(-50%);
      border: none;
      outline: none;
      box-shadow: none;
      align-items: center;
      i {
        pointer-events: none;
      }
    }
    &--prev {
      right: calc(100% + 0.5rem);
    }
    &--next {
      left: calc(100% + 0.5rem);
    }
    &__menu.has-scroll {
      border-left: 1px solid white;
      border-right: 1px solid white;
      .v-subMenu {
        &--btn {
          display: flex;
        }
      }
    }
    &__menu.start-scroll {
      border-left: none;
      .v-subMenu {
        &--prev {
          display: none;
        }
      }
    }
    &__menu.end-scroll {
      border-right: none;
      .v-subMenu {
        &--next {
          display: none;
        }
      }
    }
  }
  &.v-subMenu {
    background: none;
    border: none;
    margin: 0;
    text-transform: uppercase;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }
`;

export default class SubMenuOdv extends React.PureComponent {
  render() {
    return (
      <NavbarStyled className="hidden-sm hidden-xs v-subMenu">
        <Logo small={true} />
        <div className="v-subMenu__menu js-v-subMenu__menu">
          <button onClick={this.props.handleClickScroll} className="v-subMenu--btn v-subMenu--prev">
            <i className="fa fa-angle-double-left" aria-hidden="true" />
          </button>
          <MenuList />
          <button onClick={this.props.handleClickScroll} className="v-subMenu--btn v-subMenu--next">
            <i className="fa fa-angle-double-right" aria-hidden="true" />
          </button>
        </div>
      </NavbarStyled>
    );
  }
}
