import _ from 'lodash';

export function hasSubscription() {
  try {
    const {
      profile: { subscription_plan_info },
    } = this;
    return !_.isEmpty(subscription_plan_info);
  } catch (error) {
    return false;
  }
}

export function getSubscription() {
  try {
    const {
      profile: { subscription_plan_info },
    } = this;
    return subscription_plan_info;
  } catch (error) {
    return false;
  }
}

export function getSubscriptionName() {
  try {
    return this?.profile?.subscription_plan_info?.name ?? 'SCTVOnline BASIC';
  } catch (error) {
    return 'SCTVOnline BASIC';
  }
}

export function isSameAccount(otherAccount) {
  try {
    return this?.profile?.id === otherAccount?.profile?.id;
  } catch (error) {
    return false;
  }
}

export function getUsername() {
  try {
    const { profile } = this ?? {};
    return profile?.phone_number ?? profile?.email;
  } catch (error) {
    return '';
  }
}

export function getProfileId() {
  try {
    const { profile } = this ?? {};
    return profile?.id;
  } catch (error) {
    return '';
  }
}

export function getKeyReactByProfileId() {
  try {
    return getProfileId.call(this) ?? 'anonymous';
  } catch (error) {
    return '';
  }
}

export function isLoggedIn() {
  return this !== null;
}
