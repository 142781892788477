import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import { ButtonToolbar, Dropdown, MenuItem } from 'react-bootstrap';
import navBarId from '@/constants/ids/navBarId';
import { logout } from '@/features/auth/services';
import pushAnalytics from '@/services/analytics/Analytics';
import { Link } from 'react-router-dom';
import jquery from 'jquery';
import { getLocales } from '@/shared/utils/locales';
import { overrideMenuLink } from '../menu-item';

const locales = getLocales();

class Index extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = React.createRef();
    this.path = null;
    this.state = {};
  }
  componentDidMount() {
    this.path = this.props.location.pathname;
  }
  componentWillReceiveProps(nextProps) {
    let $el = jquery('.menuMobile');
    if (this.path !== nextProps.location.pathname) {
      $el.find('.dropdown').trigger('click');
      this.path = nextProps.location.pathname;
    }
  }

  render() {
    const { t, menu = [], language, changLanguage, account, _handelLogin, auth } = this.props;
    return (
      <ButtonToolbar className="btn-toolbar--custome menuMobile">
        <Dropdown id="menu-mobile" ref={this.toggle}>
          <Dropdown.Toggle className="toggle">
            <i className="fa fa-bars" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="navMobile">
            {menu.map((item, index) => {
              let hrefUrl =
                item.display_style === 'YOUTUBE' ? `/youtube/${item.slug}` : `/page/${item.slug}`;
              if (index === 0) {
                hrefUrl = '/';
              }
              const overrideLink = overrideMenuLink(item?.slug);
              const target = !overrideLink ? '_self' : '_blank';
              return (
                <li className="link li_item nav" key={index}>
                  <Link to={{ pathname: overrideLink ?? hrefUrl }} target={target}>
                    <h4>{item.name}</h4>
                  </Link>
                </li>
              );
            })}
            <MenuItem
              href="/favorite"
              className="link li_item nav"
              style={{ display: auth.isLogin ? null : 'none' }}
            >
              <h4>{t('myList.txtTitle')}</h4>
            </MenuItem>
            <li className="link li_item nav" style={{ display: auth.isLogin ? null : 'none' }}>
              <Link to="/profile/overview">
                <h4
                  style={{
                    color:
                      this.props.location.pathname.split('/')[1] === 'profile' ? '#fff ' : null,
                  }}
                >
                  {t('navigation.account')}
                </h4>
              </Link>
            </li>
            <li
              className="link li_item border"
              style={{ padding: '0 15px', display: auth.isLogin ? null : 'none' }}
              onClick={() =>
                pushAnalytics('click', { content_type: 'button', item_name: 'profile' })
              }
            >
              <Link to="/profile/overview">
                <h4 className="menuItem">
                  <i className="fa fa-user-circle icon" />
                  <span style={{ paddingLeft: 10 }} data-testid={navBarId.accountNameLbl}>
                    {account && account.profile
                      ? account.profile.first_name
                        ? account.profile.first_name
                        : account.profile.last_name
                      : ''}
                  </span>
                </h4>
              </Link>
            </li>
            {auth.isLogin ? (
              <li
                className="link li_item border"
                data-testid={navBarId.settingMenuId.logoutBtn}
                onClick={event => this.props._handleClickLogout(event)}
                style={{ padding: '0 15px' }}
              >
                <Link to="#">
                  <h4 className="menuItem">
                    <i className="glyphicon glyphicon-log-out" aria-hidden="true" />
                    <span style={{ paddingLeft: 10 }}>{t('navigation.logout')}</span>
                  </h4>
                </Link>
              </li>
            ) : (
              <MenuItem
                className="link li_item border"
                style={{ padding: '0 15px', display: 'none' }}
                onClick={() => _handelLogin()}
              >
                <h4 className="menuItem">
                  <i className="fa fa-user-circle icon" />
                  <span style={{ paddingLeft: 10 }} data-testid={navBarId.accountNameLbl}>
                    {t('navigation.login')}
                  </span>
                </h4>
              </MenuItem>
            )}
            <li className="li_item locales flex">
              {locales.map((locale, localeIndex) => (
                <>
                  <span
                    onClick={() => changLanguage(locale)}
                    style={{ color: language === locale ? '#2574d4' : '#fff' }}
                  >
                    {t(`locale.${locale}`)}
                  </span>
                  {localeIndex < locales.length - 1 && (
                    <span style={{ padding: '0 1rem', color: '#fff' }}>|</span>
                  )}
                </>
              ))}
            </li>
          </Dropdown.Menu>
        </Dropdown>
      </ButtonToolbar>
    );
  }
}

const mapDispatchToProps = {
  dispatchLogout: () => logout(),
};

const mapStateToProps = state => ({
  account: state.auth.account,
  auth: state.auth,
  language: state.root.language,
  menu: state?.tenantConfig?.platformConfig?.tenant_pages ?? [],
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Index)));
