import loadable from '@loadable/component';
import { routePageNames } from '@/core/routes';

const TenantPage = loadable(() => import('@/shared/pages/tenant-page/TenantPage'), {
  resolveComponent: components => components.TenantPage,
});
const Confirmation = loadable(() => import('@/shared/features/confirmation/views/index'));
const LoginSso = loadable(() => import('@/shared/features/auth/pages/loginSSO/views/loginSSOPage'));
// const DetailPage = loadable(() => import('@/features/detailpage/views/playerPage'));
const LandingPage = loadable(() => import('@/shared/features/landingpage/views/landingPage'));
const LivePage = loadable(() => import('@/shared/features/live/views/livePage'));
// const RibbonPage = loadable(() => import('@/shared/features/ribbon/views/ribbonPage'));
const RibbonPage = loadable(() => import('@/shared/pages/ribbon-page/RibbonPage'), {
  resolveComponent: components => components.RibbonPage,
});
const MyList = loadable(() => import('@/shared/features/myList/views/myList'));
const SearchPage = loadable(() => import('@/shared/features/search/views/searchPage'));
const Profile = loadable(() => import('@/shared/features/profile/views/profile'));
const Box = loadable(() => import('@/shared/features/box/views/boxPage'));
const PasswordChangePage = loadable(
  () => import('@/shared/pages/password-change/PasswordChangePage'),
  {
    resolveComponent: components => components.PasswordChangePage,
  },
);
const BlockCountry = loadable(() => import('@/shared/features/blockCountry/views/BlockCountry'));
const BoxSvod = loadable(
  () => import('@/shared/features/box/pages/subscriptionPlanPage/views/boxSubscriptionSvod'),
);
const YoutubePage = loadable(() => import('@/shared/features/youtubePage/views/index'));
// const CategoryPage = loadable(() => import('@/shared/features/categoryPage/views/index'));
const CategoryPage = loadable(() => import('@/shared/pages/category-page/CategoryPage'), {
  resolveComponent: ({ CategoryPage }) => CategoryPage,
});

const PageNotFound = loadable(() => import('@/shared/pages/404'));
const PrivacyPolicyPage = loadable(() => import('@/shared/pages/privacy-policy/PrivacyPolicyPage'));
const TermOfUsePage = loadable(() => import('@/shared/pages/term-of-use/TermOfUsePage'));
const TVLogin = loadable(() => import('@/shared/pages/tv-login/TVLogin'));
const SubscriptionChangePage = loadable(
  () => import('@/shared/pages/subscription-change/SubscriptionChangePage'),
);
const TVLoginSuccessfully = loadable(
  () => import('@/shared/pages/tv-login-successfully/TVLoginSuccessfully'),
);
const SignUpSuccessfully = loadable(
  () => import('@/shared/pages/sign-up-succesfull/SignUpSuccessfulPage'),
  {
    resolveComponent: components => components.SignUpSuccessfulPage,
  },
);
const ValidateCodePage = loadable(() => import('@/shared/pages/validate-code/ValidateCodePage'));

const ReviewUIPage = loadable(() => import('@/shared/pages/review-ui/ReviewUI'), {
  resolveComponent: components => components.ReviewUI,
});

const ContentPlayerPage = loadable(
  () => import('@/shared/pages/content-player/ContentPlayerPage'),
  {
    resolveComponent: components => components.ContentPlayerPage,
  },
);
const DocumentPage = loadable(() => import('@/shared/pages/document-page/DocumentPage'), {
  resolveComponent: components => components.DocumentPage,
});
const DetailPage = loadable(() => import('@/shared/pages/content-detail/ContentDetailPage'), {
  resolveComponent: components => components.ContentDetailPage,
  ssr: false,
});

const SignInUpPage = loadable(() => import('@/shared/pages/sign-in-up/SignInUpPage'), {
  resolveComponent: components => components.SignInUpPage,
});

const routes = [
  { path: '/review-ui', exact: true, main: () => ReviewUIPage },

  {
    path: '/account/confirmation',
    exact: true,
    main: () => Confirmation,
  },
  {
    path: '/auth/login/sso',
    exact: true,
    main: () => LoginSso,
  },
  {
    path: '/terms-of-use',
    exact: true,
    main: () => TermOfUsePage,
  },
  {
    path: '/privacy-policy',
    exact: true,
    main: () => PrivacyPolicyPage,
  },
  {
    path: '/detail/:slug',
    exact: true,
    main: () => DetailPage,
  },
  {
    path: '/landing/:slug',
    exact: true,
    main: () => LandingPage,
  },
  {
    path: '/live/:slug',
    exact: true,
    main: () => LivePage,
  },

  {
    path: '/ribbon/:slug',
    exact: true,
    main: () => RibbonPage,
  },
  {
    path: '/favorite',
    exact: true,
    main: () => MyList,
  },
  {
    path: '/search/:keyword',
    exact: true,
    main: () => SearchPage,
  },
  {
    path: '/search/:keyword/page/:page',
    exact: true,
    main: () => SearchPage,
  },
  {
    path: '/profile/:id',
    exact: true,
    main: () => Profile,
  },
  {
    path: '/help',
    exact: true,
    main: () => Profile,
  },
  {
    switch: true,
    exact: true,
    router: [
      {
        path: '/faq/:slugBlockAds',
      },
      {
        path: '/faq',
      },
    ],
    main: () => Profile,
  },

  {
    path: '/category/:slug',
    exact: true,
    main: () => CategoryPage,
  },
  // {
  //   path: '/blocks',
  //   exact: true,
  //   main: () => UpgradeSubscription
  // },
  {
    path: routePageNames.passwordChange,
    exact: true,
    main: () => PasswordChangePage,
  },
  {
    path: routePageNames.blockCountry,
    exact: true,
    main: () => BlockCountry,
  },
  {
    path: '/box/:plan',
    exact: true,
    main: () => Box,
  },
  {
    switch: true,
    exact: true,
    router: [
      {
        path: '/:lg/box/offers/svod',
        flag: true,
        main: () => BoxSvod,
        data: {
          svod: true,
        },
      },
      {
        path: routePageNames.paymentPlan,
      },
      {
        path: '/box',
      },
      {
        path: '/:lg/box',
      },
    ],
    main: () => Box,
  },
  {
    path: '/youtube/:slug',
    exact: true,
    main: () => YoutubePage,
  },
  {
    path: '/youtube/:slug/search/:keyword',
    exact: false,
    main: () => YoutubePage,
  },
  {
    path: routePageNames.signInSignUp,
    exact: true,
    main: () => SignInUpPage,
  },
  {
    path: routePageNames.signUpSuccessfully,
    exact: true,
    main: () => SignUpSuccessfully,
  },
  {
    path: routePageNames.tvSignInUp,
    exact: true,
    main: () => TVLogin,
  },
  {
    path: routePageNames.tvLoginSuccessfully,
    exact: true,
    main: () => TVLoginSuccessfully,
  },
  {
    path: routePageNames.subscriptionChange,
    exact: true,
    main: () => SubscriptionChangePage,
  },
  {
    path: routePageNames.validateCode,
    exact: true,
    main: () => ValidateCodePage,
  },
  {
    path: routePageNames.documentPage,
    exact: true,
    main: () => DocumentPage,
  },
  {
    path: routePageNames.contentPlayer,
    exact: true,
    main: () => ContentPlayerPage,
  },
  {
    path: routePageNames.tenantPageBySlug,
    exact: true,
    main: () => TenantPage,
  },
  {
    path: routePageNames.homePage,
    exact: true,
    main: () => TenantPage,
  },
  {
    exact: true,
    path: ['/404', '*'],
    main: () => PageNotFound,
  },
];
export default routes;
