import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, ButtonToolbar, Dropdown } from 'react-bootstrap';
import { logout } from '@/features/auth/services';
import * as APIString from '@/constants/apiString';
import { withTranslation } from 'react-i18next';
import { changeLanguage } from '../../../../app/services';
import './index.css';
import navBarId from '@/constants/ids/navBarId';
import { styled } from 'twin.macro';
import MenuMobile from '../mobile-menu';
import pushAnalytics, { pushAnalyticsNotSlug } from '@/services/analytics/Analytics';
import { getConfigByKey } from '@/features/tenantConfig/services';
import SearchBox from '../search-box';
import SwitchLanguage from '../switch-language';
import { DEFAULT_LOCALE } from '@/i18n';
import _ from 'lodash';

import { getInfoSubscriptionUser } from '@/core/method/subScriptionPlanMethod';
import { getSubscription, hasSubscription } from '@/core/method/accountMethod';
import { setLoadingPage } from '@/shared/features/loadingPage/services';

class Index extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAccountMenu: false,
      // eslint-disable-next-line no-undef
      language: DEFAULT_LOCALE,
      premium: true,
    };
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({
      showAccountMenu: !this.state.showAccountMenu,
    });
  }

  _actionChange(value) {
    const { i18n, dispatchChangelanguage, history, planSelected } = this.props;
    let itemName = 'selected language in vietnamese';
    if (value === 'en') {
      itemName = 'selected language in english';
    }
    i18n.changeLanguage(value);
    dispatchChangelanguage(value).then(() => {
      pushAnalytics('click', { content_type: 'button', item_name: itemName });
      let url = window.location.pathname;
      let search = window.location.search;
      const ls = { en: 'en', vi: 'vi' };
      const pathSplit = url.split('/');
      if (pathSplit.length > 1 && pathSplit[1] in ls) {
        pathSplit[1] = value;
        url = pathSplit.join('/');
        // window.location.replace(`${url}${search}`)
        history.replace(`${url}${search}`, {
          upgrade: planSelected && planSelected.upgrade ? planSelected.upgrade : null,
        });
        setTimeout(function () {
          window.location.reload();
        }, 100);
      } else {
        setTimeout(function () {
          window.location.reload();
        }, 500);
      }
    });
    this.forceUpdate();
  }

  _Login() {
    const { showLoginAction } = this.props;
    pushAnalyticsNotSlug('click', {
      content_type: 'button',
      item_name: 'Sign In',
    });
    // firebase.analytics().logEvent('click', { content_type: 'button', item_name: 'Sign In' })
    // TagManager.dataLayer({
    //   dataLayer: {
    //     'event': 'click',
    //     'item_name': 'Sign In'
    //   }
    // })
    showLoginAction();
  }

  _handleClickLogout(event) {
    const { dpSetLoadingPage } = this.props;
    pushAnalytics('click', { content_type: 'button', item_name: 'logout' });
    event && event.preventDefault();
    dpSetLoadingPage(true);
    this.props
      .dispatchLogout()
      .then(() => {
        this.props.history.push('/');
      })
      .finally(() => dpSetLoadingPage(false));
  }

  _renderDropdow() {
    const { t } = this.props;
    return (
      <ButtonToolbar>
        <Dropdown id="nav">
          <Dropdown.Toggle
            style={{
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <i className="fa fa-user-circle icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu style={styles.submenu}>
            <div className="v-nav--profile">
              <Link
                className="v-nav--profile-name"
                to="/profile/overview"
                style={styles.li_item}
                onClick={() =>
                  pushAnalytics('click', {
                    content_type: 'button',
                    item_name: 'profile',
                  })
                }
              >
                {t('navigation.profile')}
              </Link>
              <Link
                to="/"
                className="v-nav--profile-name"
                data-testid={navBarId.settingMenuId.logoutBtn}
                onClick={event => this._handleClickLogout(event)}
                style={styles.li_item}
              >
                {t('navigation.logout')}
              </Link>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </ButtonToolbar>
    );
  }

  _renderButtonPremium() {
    const { account, t, language, isLogin } = this.props;
    const flagEnbTier = getConfigByKey('features.multiTierSubscriptionEnabled');
    const subscription_plan_info = getSubscription.call(account);
    if (isLogin && subscription_plan_info) {
      return (
        <Link
          to={'/profile/paymenthistory'}
          className="v-nav--premium"
          onClick={() =>
            pushAnalytics('click', {
              content_type: 'button',
              item_name: 'VIP ON',
            })
          }
        >
          {!flagEnbTier ? (
            <Button style={{ background: '#31ad4a' }}>{t('premium.on')}</Button>
          ) : (
            <Button style={{ background: '#31ad4a' }}>
              {!_.isEmpty(subscription_plan_info) &&
                `Tài Khoản ${getInfoSubscriptionUser.call(subscription_plan_info)}`}
            </Button>
          )}
        </Link>
      );
    }
    return (
      <Link
        to={`/${language}/box/offers`}
        className="v-nav--premium"
        onClick={() =>
          pushAnalytics('click', {
            content_type: 'button',
            item_name: 'TURN OFF ADS',
          })
        }
      >
        <Button style={{ background: '#ed5858' }}>{t('premium.off')}</Button>
      </Link>
    );
  }

  _renderMenu() {
    const { isLogin, t, isFollowSubscription } = this.props;
    // todo check tenant is ProjectW and hide the premium button
    return (
      <ul className="v-nav--listMenu">
        {isFollowSubscription && this._renderButtonPremium()}
        <li style={{ margin: '0' }} className="v-nav--desktop">
          <SwitchLanguage onChange={this._actionChange.bind(this)} />
        </li>
        {isLogin ? (
          <React.Fragment>
            <Link
              to="/favorite"
              className="v-nav--desktop v-nav--myList"
              onClick={() =>
                pushAnalytics('click', {
                  content_type: 'button',
                  item_name: 'My List',
                })
              }
            >
              <li
                style={{
                  margin: '0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#fff',
                  paddingRight: 10,
                }}
              >
                <i className="v-fa v-fa-film" style={{ paddingRight: 10 }} />
                <span className="hidden-xs hidden-md hidden-sm" style={{ fontWeight: 'bold' }}>
                  {t('myList.txtTitle')}
                </span>
              </li>
            </Link>
            <li data-testid={navBarId.settingBtn} className="v-nav--desktop">
              {this._renderDropdow()}
            </li>
            <li data-testid={navBarId.settingBtn} className="v-nav--mobile">
              <MenuMobile
                changLanguage={key => this._actionChange(key)}
                _handleClickLogout={() => this._handleClickLogout()}
              />
            </li>
            <li className="slash v-nav--mobile"></li>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <li className="hidden-md hidden-lg isIpad" data-testid={navBarId.settingBtn}>
              <MenuMobile
                changLanguage={key => this._actionChange(key)}
                _handleClickLogout={() => this._handleClickLogout()}
                _handelLogin={() => this._Login()}
              />
            </li>
            <li className="slash hidden-md hidden-lg isIpad"></li>

            <li data-testid={navBarId.loginBtn} onClick={() => this._Login()} className="username ">
              {t('navigation.login')}
            </li>
          </React.Fragment>
        )}
        <SearchBox />
      </ul>
    );
  }

  render() {
    const { isLogin, t } = this.props;
    return (
      <RowStyled className="navigationTop">
        {APIString.COMMING_SOON === 'true' ? (
          <ul style={styles.flex}>
            {isLogin ? (
              <li data-testid={navBarId.settingBtn}>{this._renderDropdow()}</li>
            ) : (
              <li
                data-testid={navBarId.loginBtn}
                onClick={() => this._Login()}
                className="username"
              >
                {t('navigation.login')}
              </li>
            )}
          </ul>
        ) : (
          this._renderMenu()
        )}
      </RowStyled>
    );
  }
}
Index.propTypes = {
  isLogin: PropTypes.bool,
  dispatchLogout: PropTypes.func,
  account: PropTypes.object,
  showLoginAction: PropTypes.func,
  changeLanguage: PropTypes.func,
  menu: PropTypes.array,
};

const styles = {
  submenu: {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    top: 35,
    right: 0,
    left: 'auto',
    padding: 10,
    borderRadius: 4,
    boxShadow: '0px 15px 36px 5px rgba(0,0,0,0.45)',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    paddingLeft: 0,
  },
  li_item: {
    margin: '0',
    float: 'none',
    cursor: 'pointer',
    textAlign: 'left ',
  },
  menuItem: {
    color: '#FFFFFF',
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  nav: {
    background: '#2574d4',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  navMobile: {
    background: 'black',
    position: 'fixed',
    top: '69px',
    minWidth: '100%',
    padding: 0,
  },
};

const RowStyled = styled.div`
  &.v-nav {
  }
  .v-nav {
    &--profile {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      &-name {
        padding: 0.25rem 1rem;
        white-space: pre;
      }
    }
    &--listMenu {
      display: flex;
      justify-content: end;
      align-items: center;
      flex-direction: row-reverse;
      padding-left: 0.25rem;
      margin: 0px;
      /* flex-wrap: wrap; */
    }
    &--premium {
      display: flex;
      order: 2;
      &:hover {
        text-decoration: none;
      }
      button {
        text-transform: uppercase;
        border: none;
        color: #fff;
        font-size: 7px;
        padding: 4px 7px;
      }
    }
    &--search {
      display: flex;
      order: 1;
    }
    &--mobile {
      display: block;
    }
    &--desktop {
      display: none;
    }
    &--border {
      a {
        border-bottom: 1px solid #292929;
      }
    }
    &--link {
      &:not(.v-nav--border) {
        margin-top: -4px !important;
      }
      a {
        color: #092f7d;
        &:hover {
          background: #2574d4;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-nav {
      &--premium {
        button {
          font-size: 0.825rem;
          padding: 6px 12px;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .v-nav {
      &--premium {
        order: 0;
      }
      &--search {
        order: 0;
      }
      &--mobile {
        display: none;
      }
      &--desktop {
        display: block;
      }
    }
  }

  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

const mapDispatchToProps = {
  dispatchLogout: () => logout(),
  dispatchChangelanguage: value => changeLanguage(value),
  dpSetLoadingPage: setLoadingPage,
};

const mapStateToProps = state => ({
  isLogin: state.auth.isLogin,
  account: state.auth.account,
  language: state.root.language,
  menu: state?.tenantConfig?.platformConfig?.tenant_pages ?? [],
  planSelected: state.box.form.subscriptionPlan,
  isFollowSubscription: state.root.isFollowSubscription,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Index)));
