import { useSelector } from 'react-redux';
import { accountSelector, selectPaidContents } from '@/shared/features/auth/reducers';
import { getKeyReactByProfileId } from '@/core/method/accountMethod';
import React from 'react';

export const withKeyByAccountId = Component => {
  return props => {
    const account = useSelector(accountSelector);
    const key = `${getKeyReactByProfileId.call(account)}`;
    return <Component key={key} {...props} />;
  };
};
