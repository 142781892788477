export default {
  logoBtn: 'navbar-logo-btn',
  searchBtn: 'navbar-search-btn',
  searchTxt: 'navbar-search-txt',
  notificationBtn: 'navbar-notification-btn',
  loginBtn: 'navbar-login-btn',
  accountNameLbl: 'navbar-account-name-lbl',
  settingBtn: 'navbar-setting-btn',
  settingMenuId: {
    profileBtn: 'navbar-setting-menu-profile-btn',
    logoutBtn: 'navbar-setting-menu-logout-btn',
  },
};
